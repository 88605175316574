body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

header {
  background-color: #ffffff;
  color: white;
  text-align: center;
  padding: 1em 0;
}

header img {
  max-width: 200px;
  height: auto;
}

.main-image {
  position: relative;
  text-align: center;
  color: white;
}

.main-image img {
  width: 100%;
  height: auto;
}

.main-image .overlay-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.5);
  padding: 0px;
  border-radius: 5px;
  font-size: 24px; /* increased font size */
  opacity: 0;
  animation: revealText 4s ease forwards; /* increased animation time */
}

@keyframes revealText {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

section {
  padding: 2em;
  text-align: center;
}

h2 {
  color: #4CAF50;
}

.image-gallery {
  display: flex;
  justify-content: center;
  gap: 1em;
  flex-wrap: wrap;
}

.image-gallery img {
  width: 350px;
  height: 350px;
  object-fit: cover;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 5px;
}

.mission-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1em;
}

.mission-content p {
  max-width: 600px;
  text-align: center;
  margin: 0;
  padding: 0;
}

footer {
  background-color: #4CAF50;
  color: white;
  text-align: center;
  padding: 1em 0;
  margin-top: 20px; /* added margin between mission section and footer */
}
